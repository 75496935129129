import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/loader.css";

const ContactForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });
  const [messageSent, setMessageSent] = useState(false); // State to track if message is sent successfully
  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/; // Only letters and spaces allowed
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate inputs as the user types
    if (name === "name") {
      if (!validateName(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: t("name-validation"),
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "",
        }));
      }
    }

    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: t("email-validation"),
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation before sending the form
    if (!validateName(formData.name)) {
      alert(t("name-alert"));
      return;
    }

    if (!validateEmail(formData.email)) {
      alert(t("email-alert"));
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(
        "https://mehdimessaadidev.com/api/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setMessageSent(true);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        throw new Error("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  const handleSendAnotherMessage = () => {
    // Reset form data and errors
    setFormData({
      name: "",
      email: "",
      message: "",
    });
    setErrors({
      name: "",
      email: "",
    });
    setMessageSent(false);
  };

  if (isLoading) {
    return (
      <div className="contactForm-loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (messageSent) {
    return (
      <div className="MessageSentContainer">
        <p className="MessageSentText">{t("message-sent-successfully")}</p>
        <button className="Button-Style-One" onClick={handleSendAnotherMessage}>
          {t("send-another-message")}
        </button>
      </div>
    );
  }

  return (
    <div className="ContactForm-Container">
      <form className="ContactForm" onSubmit={handleSubmit}>
        <div className="ContactForm-NameAndEmail-Input-Container">
          <div className="ContactForm-Input-Container">
            <input
              className="ContactForm-Input"
              placeholder={t("name") + " :"}
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="ContactForm-Input-Container">
            <input
              className="ContactForm-Input"
              placeholder="Email :"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>
        <div className="ContactForm-Input-Container">
          <textarea
            className="ContactForm-Input Textarea"
            placeholder="Message :"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button className="Button-Style-One" type="submit">
          {t("send-message")}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
